<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    //
  }),
};
</script>

<style>
/* Small blue button on the left-bottom corner that activates the chat */
/* .cc-1m2mf {
  display: none !important;
} */
</style>