<template>
  <v-container>
    <v-btn @click="showDialog = true" class="exchangeScoreButton" :class="pos_class" text>
      <p class="mt-4">{{ $t('gifts_exchangeScoreButton_1') }} <br /> 
      {{ $t('gifts_exchangeScoreButton_2') }} <img src="@/assets/images/green_right_arrow.png" alt="Green right arrow" /> </p>
    </v-btn>
    <v-dialog v-model="showDialog" max-width="550px">
      <v-card class="modalContainer">
        <v-card-title class="modalHeader">{{ $t('modal_header') }}</v-card-title>
        <v-card-text class="modalText">
          {{ $t('modal_text') }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green" class="modal_button" @click="confirmed">{{ $t('modal_yes_button') }}</v-btn>
          <v-btn color="red" class="modal_button" @click="showDialog = false">{{ $t('modal_no_button') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import user from '@/store/user';
import store from '@/store';
import axios from 'axios';
export default {
  name: 'ExchangeGiftButton',
  props: {
    pos_class: {
      type: String,
      required: true
    },
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      showDialog: false,
      config: {
            headers: {
                Authorization: ""
            }
        },
    };
  },
  methods: {
    confirmed() {
      if(!user.getters.is_login){
        this.$router.push('/login')
      }
      else {
        this.config.headers.Authorization = "Bearer " + user.getters.getToken;
        axios.post(store.getters.getUrl+'Present/Buy',{"presentId": this.item.id}, this.config)
          .then(response => {
            if(response.data.success){
              this.$toast.success(this.$t('presend_bought'))
            }
            else if(response.data.errors[0].errorCode == "notEnoughMoney") {
              this.$toast.error(this.$t('not_enought_money'))
            }
            else if(response.data.errors[0].errorCode == "outOfStock") {
              this.$toast.error(this.$t('out_of_stock'))
            }
          })
          .catch(() => {
            this.$toast.error('Xəta var!')
          })
      }
      this.showDialog = false
    }
  }
}
</script>

<style>
.modalContainer {
  background-image: url('@/assets/images/main_bg.png');
  background-repeat:no-repeat;
  background-size:cover;
  display: flex !important;
  flex-direction: column !important;
  height: 15rem !important;
}
.modal_button {
  text-transform: none !important;
  color: #FFFFFF !important;
}
.modalHeader {
  color: white;
  font-size: 2rem !important;
}
.modalHeader,
.modalText,
.v-card-actions {
  flex: 1 0 auto;
}
.modalText {
  color: #517446;
  font-size: 1.2rem !important;
  flex: 1 1 auto;
}
.v-card-actions {
  display: flex;
  justify-content: flex-end;
}
</style>