import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import { router } from './router'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
import store from './store';
import VueScrollTo from 'vue-scrollto';
import i18n from './plugins/i18n';
import VueQrcodeReader from 'vue-qrcode-reader'

Vue.use(VueQrcodeReader)
// import VueZxingScanner from "vue-zxing-scanner";

// Vue.use(VueZxingScanner);
Vue.config.productionTip = false

Vue.use(VueToast,{
  position:'top-left'
});

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})

new Vue({
  vuetify,
  render: h => h(App),
  router,
  store,
  i18n
}).$mount('#app')
