<template>
    <v-main>
      <div class="menu2" id="lotereya">
        <Header2/>

          <!-- ------------------------------------------------------------ -->

        <v-container>
          <div class="menus" style="height:600px">
            <h1 class="tile">ŞİFRƏNİ DƏYİŞ</h1>
            <v-row class="pt-3">
              <v-col
              cols="12"
              lg="12"
              md="12"
              sm="12"
              class="pb-0 text-center mt-sm-9"
              >
              <v-row>
                <v-spacer></v-spacer>
                <v-card 
                  elevation="0"
                  class="px-5 mx-4 login-cart justify-center"
                  >
                  <v-card-text>
                    <v-form @submit.prevent="changePassword" id="sign" ref="form">
                      <v-text-field
                        class="form_field"
                        label="Köhnə şifrə *"
                        v-model="oldpassword"
                        :rules="rules.password"
                        type="password"
                        dark
                        outlined
                        color="#f6d199"
                        text-color="#f6d199"
                      ></v-text-field>
                      <v-text-field
                        class="form_field"
                        label="Yeni şifrə *"
                        v-model="password"
                        :rules="rules.password"
                        type="password"
                        dark
                        outlined
                        color="#f6d199"
                        text-color="#f6d199"
                      ></v-text-field>
                      <v-text-field
                        class="form_field"
                        label="Yeni şifrənin təkrarı *"
                        v-model="repassword"
                        :rules="rules.req"
                        type="password"
                        dark
                        outlined
                        color="#f6d199"
                        text-color="#f6d199"
                      ></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn  
                    class="px-10 buttonh"
                    form="sign"
                    :loading="loading"
                    dark
                    rounded
                    type="submit"
                    >DƏYİŞ</v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
                <v-spacer></v-spacer>
              </v-row>
              </v-col>
            </v-row>
          </div>
        </v-container>


          <!-- ----------------------------------------------------------------- -->
          <div>
            <v-row class="pt-3">
              <v-col
              cols="12"
              lg="12"
              md="12"
              sm="12"
              class="pb-0 text-center my-sm-9"
              >
              <p class="t4">ƏLAVƏ MƏLUMAT VƏ SUALLAR ÜÇÜN +994 99 820 68 20 NÖMRƏSİNƏ MÜRACİƏT EDƏ BİLƏRSİNİZ (WHATSAPP UZƏRINDƏN ZƏNGLƏR PULSUZDUR).</p>  
            </v-col>
            </v-row>
          </div>
      </div>

    </v-main>
</template>
<script>
import axios from "axios";
import user from "../../store/user"
import store from "../../store/index"
import Header2 from "../parts/Header2.vue";
  export default {
    data: () => ({
      config: {
            headers: {
                Authorization: ""
            }
        },
      password : '',
      oldpassword :'',
      repassword : '',
      loading:false,
      rules : {
        req: [
          val => val != '' || 'Xahiş edirik, * işarəsi olan sətirləri tam doldurasınız. '
        ],
        password: [
          val => val != '' || "Xahiş edirik, * işarəsi olan sətirləri tam doldurasınız. ",
          val => val.length >= 6 || 'Şifrəniz çox qısadır, ən azı 6 simvol olmalıdır'
        ],
      }
    }),
    methods: {
      changePassword(){
        if(this.password!=this.repassword){
          this.$toast.error('Şifrənin təkrarını doğru yazmadınız.')
        }
        else if(this.$refs.form.validate()){
          this.loading = true
          axios.post(store.getters.getUrl+'user/changepassword',{oldPassword:this.oldpassword, newPassword: this.password}, this.config)
          .then(response => {
            if(response.data.success){
              this.$toast.success('Success')
            
              user.dispatch('updateLogin',{user:'', expires:-1})

              this.loading = false
              location.href='/login'
            }
            else if(response.data.errors[0].errorMsg=="wrong_password"){
              this.$toast.error('Köhnə parol düzgün deyil!')
              this.loading = false
            }
            else {
              this.$toast.error('Xəta var!')
              this.loading = false
            }
          })
        }
        else{
          this.$toast.error('Xanalar düzgün doldurulmayıb!')
          this.loading = false
        }
      },
    },
    created(){
      this.config.headers.Authorization = "Bearer " + user.getters.getToken;
    },

    components: { Header2 }
}
</script>
<style>
@import url('../../assets/css/custom.css');
</style>