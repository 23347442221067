<template>
  <v-main class="menu">
    <!-- Upper part -->
    <div class="menu1">
      <Header />
      <p class="menu1_head1" id="head">{{ $t('upper_text_1') }} <br /> {{ $t('upper_text_2') }} </p>
      <p class="menu1_head2 mt-8">{{ $t('upper_date') }}</p>
      <v-row class="pt-md-10 pt-sm-3">
        <v-col
        cols="12"
        lg="10"
        md="11"
        sm="12"
        class="py-0 text-center d-flex flex-column justify-content-center max-width scp"
        >
          <img src="@/assets/images/presents_group.png" alt="Presents" class="presents_group_img">
          <div class="text-center scp1">
            <button @click="toFiscalEnter" class="button_check mx-auto px-16 py-2">{{ $t('conditions_uploadCheck_button') }}</button>
          </div>
        </v-col>
      </v-row>



    </div>

    <!-- Conditions -->
    <div id="conditions">
      <v-container class="py-0">
        <v-row class="pt-16 tessContainer">
          <!-- Condition 1 -->
          <v-col
          cols="12"
          lg="3"
          md="6"
          sm="12"
          class="text-center"
          >
            <div>
              <div class="d-flex flex-row align-center ensuringJustifyCenter">
                <p class="conditionNumber">1</p>
                <img src="@/assets/images/conditions/milk.png" alt="Milk">
              </div>

              <div class="d-flex flex-column conditionsMargin">
                <p class="conditionHeader">{{ $t('conditions_1_big_text') }}</p>
                <p class="conditionInfo">{{ $t('conditions_1_small_text_1') }}<br />{{ $t('conditions_1_small_text_2') }}</p>
              </div>
            </div>
          </v-col>
          
          <!-- Condition 2 -->
          <v-col
          cols="12"
          lg="3"
          md="6"
          sm="12"
          class="text-center condition_mt"
          >
            <div>
              <div class="d-flex flex-row align-center ensuringJustifyCenter">
                <p class="conditionNumber">2</p>
                <img src="@/assets/images/conditions/check.png" alt="Check">
              </div>

              <div class="d-flex flex-column conditionsMargin">
                <p class="conditionHeader">{{ $t('conditions_2_big_text') }}</p>
                <p class="conditionInfo">{{ $t('conditions_2_small_text') }}</p>
              </div>
            </div>
          </v-col>

          <!-- Condition 3 -->
          <v-col
          cols="12"
          lg="3"
          md="6"
          sm="12"
          class="text-center condition_mt"
          >
            <div>
              <div class="d-flex flex-row align-center ensuringJustifyCenter">
                <p class="conditionNumber">3</p>
                <img src="@/assets/images/conditions/phone.png" alt="Bad and present">
              </div>

              <div class="d-flex flex-column conditionsMargin">
                <p class="conditionHeader">{{ $t('conditions_3_big_text') }}</p>
                <p class="conditionInfo">{{ $t('conditions_3_small_text_1') }}<br />{{ $t('conditions_3_small_text_2') }}</p>
              </div>
            </div>
          </v-col>
          
          <!-- Condition 4 -->
          <v-col
          cols="12"
          lg="3"
          md="6"
          sm="12"
          class="text-center condition_mt"
          >
            <div>
              <div class="d-flex flex-row align-center ensuringJustifyCenter">
                <p class="conditionNumber">4</p>
                <img src="@/assets/images/conditions/bag_n_present.png" alt="Bad and present">
              </div>

              <div class="d-flex flex-column conditionsMargin">
                <p class="conditionHeader">{{ $t('conditions_4_big_text') }}</p>
                <p class="conditionInfo">{{ $t('conditions_4_small_text') }}</p>
              </div>
            </div>
          </v-col>
        </v-row>

        <!-- "Upload check" button -->
        <div class="text-center">
          <button @click="toFiscalEnter" class="button_check mx-auto px-16 py-2">{{ $t('conditions_uploadCheck_button') }}</button>
        </div>
      </v-container>
    </div>

    <!-- Gifts -->
    <div id="gifts" class="my-16">
      <v-container class="py-0">
        <p class="menu1_head1">{{ $t('gifts_header') }}</p>

        <div class="giftsContainer mt-16">
          <!-- 1 Manat = 50 Bal -->
          <div class="gmanatbal pAbsolute">
            <p class="giftName">{{ $t('gifts_manatbal_1') }}<br class="hidden-md-and-up" />{{ $t('gifts_manatbal_2') }}</p>
          </div>
          
          <!-- Shopping bag -->
          <div class="gshoppingBag pAbsolute">
            <div class="pRelative">
              <p class="giftName gshoppingBagText">{{ $t('gifts_shoppingBag_text') }}</p>
              <img :src="$t('1500_points')" alt="1500 points" class="score gshoppingBagScore">
              <img class="gshoppingBagImage" src="@/assets/images/gifts/shopping_bag.png" alt="Shopping bag" />
              <ExchangeGiftButton :item="presents[0]" pos_class="gshoppingBagButton" />
            </div>
          </div>

          <!-- Cup -->
          <div class="gcup pAbsolute">
            <div class="pRelative">
              <p class="giftName gcupText">{{ $t('gifts_cup_text') }}</p>
              <img :src="$t('2000_points')" alt="2000 points" class="score gcupScore">
              <img class="gcupImage" src="@/assets/images/gifts/cup.png" alt="Cup" />
              <ExchangeGiftButton :item="presents[1]"  pos_class="gcupButton" />
            </div>
          </div>

          <!-- T-shirt -->
          <div class="gtshirt pAbsolute">
            <div class="pRelative">
              <p class="giftName gtshirtText">{{ $t('gifts_tshirt_text') }}</p>
              <img :src="$t('2200_points')" alt="2200 points" class="score gtshirtScore">
              <img class="gtshirtImage" src="@/assets/images/gifts/t-shirt.png" alt="T-shirt" />
              <ExchangeGiftButton :item="presents[2]" pos_class="gtshirtButton" />
            </div>
          </div>

          <!-- Tea set -->
          <div class="gteaSet pAbsolute">
            <div class="pRelative">
              <p class="giftName gteaSetText">{{ $t('gifts_teaSet_text') }}</p>
              <img :src="$t('3300_points')" alt="3300 points" class="score gteaSetScore">
              <img class="gteaSetImage" src="@/assets/images/gifts/tea_set.png" alt="Tea set" />
              <ExchangeGiftButton :item="presents[3]" pos_class="gteaSetButton" />
            </div>
          </div>

          <!-- Flight pillow -->
          <div class="gflightPillow pAbsolute">
            <div class="pRelative">
              <p class="giftName gflightPillowText">{{ $t('gifts_flightPillow_text_1') }}<br class="hidden-sm-and-down" />{{ $t('gifts_flightPillow_text_2') }}</p>
              <img :src="$t('5000_points')" alt="5000 points" class="score gflightPillowScore">
              <img class="gflightPillowImage" src="@/assets/images/gifts/flight_pillow.png" alt="Flight pillow" />
              <ExchangeGiftButton :item="presents[4]" pos_class="gflightPillowButton" />
            </div>
          </div>

          <!-- Hoodie -->
          <div class="ghoodie pAbsolute">
            <div class="pRelative">
              <p class="giftName ghoodieText">{{ $t('gifts_hoodie_text') }}</p>
              <img :src="$t('6500_points')" alt="6500 points" class="score ghoodieScore">
              <img class="ghoodieImage" src="@/assets/images/gifts/hoodie.png" alt="Hoodie" />
              <ExchangeGiftButton :item="presents[7]" pos_class="ghoodieButton" />
            </div>
          </div>

          <!-- Backpack -->
          <div class="gbackpack pAbsolute">
            <div class="pRelative">
              <p class="giftName gbackpackText">{{ $t('gifts_backpack_text') }}</p>
              <img :src="$t('11000_points')" alt="11000 points" class="score gbackpackScore">
              <img class="gbackpackImage" src="@/assets/images/gifts/backpack.png" alt="Backpack" />
              <ExchangeGiftButton :item="presents[5]" pos_class="gbackpackButton" />
            </div>
          </div>

          <!-- Bag -->
          <div class="gbag pAbsolute">
            <div class="pRelative">
              <p class="giftName gbagText">{{ $t('gifts_bag_text') }}</p>
              <img :src="$t('10500_points')" alt="10500 points" class="score gbagScore">
              <img class="gbagImage" src="@/assets/images/gifts/bag.png" alt="Bag" />
              <ExchangeGiftButton :item="presents[6]" pos_class="gbagButton" />
            </div>
          </div>
        </div>
      </v-container>
    </div>

    <!-- FAQ -->
    <div id="faq" class="my-16">
      <p class="menu1_head1">{{ $t('faq_header') }}</p>
      
      <div class="container">
        <div class="tessContainer">
          <div class="faqContainer">
            <v-row>
              <!-- Questions and Answers -->
              <v-col 
                cols="12"
                lg="8"
                md="8"
                sm="12"
                class="questionsContainer">
                <QuestionsAnswers />
              </v-col>
              
              <!-- Contact Us -->
              <v-col 
                cols="12"
                lg="4"
                md="4"
                sm="12"
                class="contactUsContainer"
              >
                <p class="contactUsText">{{ $t('faq_contactUs_1') }}<br />{{ $t('faq_contactUs_2') }}<br />{{ $t('faq_contactUs_3') }}</p>
                <v-btn class="askQuestionButton" @click.prevent="toggleChatBot" text>{{ $t('faq_contactUs_button') }}</v-btn>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
      <v-container>
        <p class="mt-10 green-text">{{ $t('hot_line') }}</p>
      </v-container>
    </div>
  </v-main>
</template>

<script>
import Header from "../parts/Header.vue"
import QuestionsAnswers from '../parts/QuestionsAnswers.vue'
import ExchangeGiftButton from '../parts/ExchangeGiftButton.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Index',
  data: () => ({
    items: [],
    presents: [
      {"id": 1, "value": 1500},
      {"id": 2, "value": 2000},
      {"id": 3, "value": 2200},
      {"id": 4, "value": 3500},
      {"id": 5, "value": 4500},
      {"id": 6, "value": 6500},
      {"id": 7, "value": 7000},
      {"id": 8, "value": 8000},
      
    ],
    chatBotVisible: false
  }),
  components: { Header, QuestionsAnswers, ExchangeGiftButton },
  methods: {
    toFiscalEnter() {
      this.$router.push('/fiscalEnter')
    },

    toggleChatBot() {
      this.chatBotVisible = !this.chatBotVisible
      this.chatBotVisible ? document.querySelector('.cc-1no03').setAttribute('data-visible', 'true') : document.querySelector('.cc-1no03').setAttribute('data-visible', 'false') 
    }
  }
}
</script>

<style>
@import url('../../assets/css/custom.css');
</style>