<template>
  <v-main class="menu2" theme="light">
    <Header2 :pageIsLogin="false" />
    <v-container fluid>
      <v-row class="login-row">
        <!-- Tea set image -->
        <v-col
          cols="12"
          sm="0"
          md="6"
          class="d-none d-md-flex no-padding"
        >
          <img src="@/assets/images/gifts/tea_set.png" alt="Tess tea set" class="max-width">
        </v-col>

        <!-- Form -->
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="no-padding d-none d-md-flex justify-center d-flex align-self-center"
        >
          <v-card elevation="0" class="px-5 mx-4 login-cart justify-center">
            <v-card-text>
              <v-form @submit.prevent="register" id="sign" ref="form">
                
                <!-- Name -->
                <v-text-field
                  class="mb-3 form_field" 
                  :label="$t('register_field_name')"
                  v-model="request.name"
                  :rules='rules.req'
                  type="text"
                  outlined
                  color="#11B800"
                ></v-text-field>
                
                <!-- Surname -->
                <v-text-field
                  class="mb-3 form_field"
                  :label="$t('register_field_surname')"
                  v-model="request.surname"
                  :rules='rules.req'
                  type="text"
                  outlined
                  color="#11B800"
                ></v-text-field>
                
                <!-- Mobile number -->
                <v-text-field
                  class="mb-3 form_field"
                  :label="$t('register_field_mobile')"
                  v-model="request.msisdn"
                  :rules='rules.mobile'
                  type="text"
                  outlined
                  color="#11B800"
                ></v-text-field>
                
                <!-- Email -->
                <v-text-field
                  class="mb-3 form_field"
                  :label="$t('register_field_email')"
                  v-model="request.email"
                  :rules='rules.email'
                  type="text"
                  outlined
                  color="#11B800"
                ></v-text-field>

                <!-- Password -->
                <v-text-field
                  class="mb-3 form_field"
                  :label="$t('register_field_password')"
                  v-model="request.password"
                  :rules="rules.password"
                  type="password"
                  outlined
                  color="#11B800"
                ></v-text-field>

                <!-- Confirm Password -->
                <v-text-field
                  class="form_field "
                  :label="$t('register_field_confirmPassword')"
                  v-model="request.confirmPassword"
                  :rules="rules.confirmPassword"
                  type="password"
                  outlined
                  color="#11B800"
                ></v-text-field>
                
                <!-- Terms and Conditions -->
                <v-checkbox
                  v-model="check"
                  :label="$t('register_field_tac')"
                  color="#FFFFFF"
                  class="custom-checkbox"
                ></v-checkbox>
              </v-form>
            </v-card-text>
              
            <!-- Register button -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn class="px-10 buttonh" form="sign" :disabled="!check" type="submit" :loading="loading" rounded>
                {{ $t('register_button') }}
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import axios from "axios"
import store from '../store'
import Header2 from "./parts/Header2.vue"
import i18n from "@/plugins/i18n"

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Register',
  components: { Header2 },
  data: () => ({
    request: {
      msisdn: "",
      name: "",
      surname: "",
      email: "",
      password: ""
    },
    check: false,
    loading: false,
    rules: {}
  }),
  methods: {
    register() {
      if (this.$refs.form.validate()) {
        if (994000000000 > Number(this.request.msisdn)) {
          this.request.msisdn = 994000000000 + Number(this.request.msisdn);
        }
        this.request.msisdn = this.request.msisdn.toString()
        this.loading = true;
        axios.post(store.getters.getUrl + "User/register", this.request)
        .then(response => {
          if (response.data.success) {
            this.$toast.success(i18n.t('register_success'));
            this.loading = false;
            this.$router.push('/login')
          }
          else{
            this.$toast.error(i18n.t('register_error'));
            this.loading = false;
          }
        })
        .catch(() => {
          this.$toast.error(i18n.t('register_error'));
          this.loading = false;
        })
      }
      else {
        this.$toast.error(i18n.t('register_wrong_filling'));
        this.loading = false;
      }
    },
  },
  created() {
    this.rules = {
      req: [
        val => val != "" || i18n.t('register_error_please_fill')
      ],
      password: [
        val => val !== "" || i18n.t('register_error_please_fill'),
        val => val.length >= 6 || i18n.t('register_error_password_too_short')
      ],
      confirmPassword: [
        val => val !== "" || i18n.t('register_error_please_fill'),
        val => val === this.request.password || i18n.t('register_error_passwords_dont_match')
      ],
      mobile: [
        val => val > 500000000 && val < 994999999999 || i18n.t('register_error_number_example'),
        val => !val || /^[0-9]*\.?[0-9]*$/.test(val) || i18n.t('register_error_number_format_wrong')
      ],
      email: [
        val => val !== "" || i18n.t('register_error_please_fill'),
        val => !val || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(val) || i18n.t('register_error_email_format_wrong')
      ],
    }
  }
}
</script>

<style scoped>
.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) >>> fieldset {
  border-color: #175A33;
}
</style>

<style>
@import url('../assets/css/custom.css');
.login-cart{
  width: 650px;
}
.login-row{
  width: 100%!important;
  height: 100%;
}
.all-page{
  width: 100%;
  height: 100%;
}
</style>